@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.searched-member {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-radius: corner-radius.$s;
    background-color: light.$surface-primary-hover;
  }

  &:not(:last-child) {
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__tag {
    display: flex;
  }

  &__description {
    color: light.$on-surface-primary-alternate;
  }

  &__link {
    flex-grow: 1;
    padding: spacing.$s 0;
    padding-left: spacing.$s;
    text-decoration: none;
  }

  &__assign {
    padding-right: spacing.$s;
  }
}
